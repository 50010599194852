<template>
  <div class="my">
    <div class="myTop">
         <router-link :to="{path:'set'}" class="setBtn"> <span>设置</span></router-link>
        <p class="phone">
          <span>{{mobilePhone}}</span>
        </p>
        <p class="money">
          <span>累计签约金额(元)<van-icon name="question-o" @click="tips(1)"/></span>
          <span>{{detailData.sumCaptial}}</span>
        </p>
      <div>
      <ul>
        <li>
          <p>已成立统计(元)<van-icon name="question-o" @click="tips(2)"/></p>
          <p>{{detailData.buildMoney}}</p>
        </li>
        <li>
          <p>已分配统计(元)<van-icon name="question-o" @click="tips(3)"/></p>
          <p>{{detailData.cash}}</p>
        </li>
      </ul>
      </div>
    </div>
    <div class="myCon">
      <ul>
        <li>
          <span>实名认证</span>
          <template v-if="status == 0">
            <router-link :to="{path:'realName'}" class="setBtn">
             <span>未认证<van-icon name="arrow" /></span>
            </router-link>
          </template>
          <template v-else-if="status == 1">
           <span>已实名</span>
          </template>
        </li>
        <router-link :to="{path:'signList'}">
          <li>
            <span>签约管理</span>
            <span><van-icon name="arrow" /></span>
          
          </li>
        </router-link>
        <li @click="goBandCard()">
          <span>银行卡管理</span>
          <span ><van-icon name="arrow" /></span>
          <!-- <span>**** **** **** ****9806</span> -->
        </li>
        <li>
          <span>我的理财经理</span>
           <template v-if="manager ==''">
              <td>未分配</td>
          </template>
          <template v-else>
            <span>{{manager}}</span>
          </template>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { myAccount,getUserManagerByUserId} from "../services/proApi";
import { encryptRSA, decryptRSA, encryptAES, decryptAES } from "../utils/encryption";
import { queryUserIsRealName} from "../services/api";
export default {
  data() {
    return {
      detailData:{ },
      status:"",
      manager:'',
      mobilePhone:'',
    };
  },
  created: function() {
    const mobilePhone = localStorage.getItem("mobilePhone")
    this.mobilePhone=mobilePhone.replace(/^(.{3})(?:\d+)(.{4})$/,"$1****$2")
    this.checkUserIsFinancial();
    this.queryUserIsRealName();
    this.getUserManagerByUserId();
  },
  methods: {
    async queryUserIsRealName() {//校验实名接口
      const _this = this;
      const datas = await queryUserIsRealName({ });
          let encrypted = datas.data.encrypted;
          let data = datas.data.msg;
          let aseKey = decryptRSA(encrypted); //RSA解密
          let res = JSON.parse(decryptAES(data, aseKey)); //AES解密
          console.log(res);
          //status 0是未实名 1是已实名
          _this.status=res.status;
     
    },
    async getUserManagerByUserId() {//理财经理
      const userId = localStorage.getItem("userId")

      const datas = await getUserManagerByUserId({ userId:userId});
      this.manager=datas.data.managerName+datas.data.managerPhone;
      console.log(datas.data.managerName+datas.data.managerPhone)
     
    },
    goBandCard(){//去绑卡
      this.$router.push({
        path: "myBank", query: {}
      });

    },
    async checkUserIsFinancial() {
      const datas = await myAccount({});
      if (datas.code == 1) {
         this.detailData=datas.data;
      }else if(datas.code == 401){//未登录
        this.$router.push({
             path: "login", query: {}
        });
      }
    },
   tips(type){
       switch(type) {
            case 1:
                this.$toast('已成立本金之和+已分配本金之和');
                break;
            case 2:
                this.$toast('已起息，未分配的本金之和');
                break;
            default:
                this.$toast('所有已分配的本息之和 ');
        }
       
    },
    
  }
};
</script>
<style lang="scss" scoped>
.my{
   .myTop{
      padding:3rem 2rem;
      background: linear-gradient(0deg, #fff, #0398FF);
      font-size:0.9rem;position: relative;
      .phone{display: flex;justify-content: space-between;}
      .phone:first-child{font-size:1rem;background:none;padding-top:2rem;}
      .setBtn{
        background: #0398FF;
        color: #fff;
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        font-size: 0.8rem;
        position:absolute;
        right:1rem;top:2rem;
        }
      .money{
        border-bottom:0.01rem solid #fff;
        padding-top:1rem;
        padding-bottom: 1rem;
        span:first-child{padding-right:5rem;}
        span:last-child{font-size:1rem;}
      }
      ul{
        padding-top:1rem;
        li{
          display: inline-block;width: 49%;
          p{text-align: center;display: block;line-height:2.4rem;
          i{padding-left:0.1rem}
          }
          p:last-child{color:#0398FF;font-size: 1rem;}
          a{display: block;}
        }
        li:first-child{border-right:0.01rem solid #fff;}
      }

    }
    .myCon{
      ul{
        background: #fff;
        margin-top:1rem;
        a{color:#333;}
        li{
          display: flex;justify-content: space-between;
          padding:0 1rem;
          margin-bottom: -1px;
          border: 1px solid #e6e6e6;
          border-width: 1px 0;
          min-height: 1rem;
          align-items: center;
          height:3rem;
          line-height:3rem;
          font-size:0.9rem;
        }
      }
    }
}


 

</style>
